import React from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import {
    heroProps,
    preFooterTitle,
    cardsDataPrefooter,
    ReviewsPrefooter,
    packageIncludesProps,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import useResponsive from '../../lib/hooks/useResponsive'
import { BREAKPOINTS } from '../../lib/constants/breakpoints'
import { PricingCard } from './pricing-card'

import backgroundImageMobile from '../../../resources/images/hanzo/testimonials/background_mobile.webp'
import backgroundImage from '../../../resources/images/hanzo/testimonials/background.webp'
import Wrapper from '../../lib/components/basics/wrapper'

const PricingPage = () => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })

    return (
        <div className={styles.pricing_page}>
            <div className={styles.content}>
                <HeroModule
                    {...heroProps}
                    background={{
                        image: {
                            src: isMobile ? backgroundImageMobile : backgroundImage,
                            alt: 'pattern',
                        },
                    }}
                />

                <Wrapper type='modal'>
                    <div className='grid grid-cols-1 gap-4 xl:grid-cols-3 md:gap-8 lg:gap-14 py-8 lg:py-14 px-4 md:px-0'>
                        <PricingCard.Wrapper>
                            <PricingCard.Title
                                icon={
                                    <svg
                                        width='68'
                                        height='42'
                                        viewBox='0 0 68 42'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M60.8566 10.49C60.8566 14.36 57.7199 17.4967 53.8499 17.4967C49.9799 17.4967 46.8433 14.36 46.8433 10.49C46.8433 6.62001 49.9799 3.48334 53.8499 3.48334C57.7199 3.48334 60.8566 6.62001 60.8566 10.49Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M34 1.15002C38.5133 1.15002 42.1733 4.81003 42.1733 9.32336C42.1733 13.8367 38.5133 17.4967 34 17.4967C29.4866 17.4967 25.8266 13.8367 25.8266 9.32336C25.8266 4.81003 29.4866 1.15002 34 1.15002ZM14.15 3.48336C18.02 3.48336 21.1566 6.62003 21.1566 10.49C21.1566 14.36 18.02 17.4967 14.15 17.4967C10.28 17.4967 7.14331 14.36 7.14331 10.49C7.14331 6.62003 10.28 3.48336 14.15 3.48336Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M53.85 38.5167H62.85C65.56 38.5167 67.76 36.19 67.0333 33.58C64.15 23.22 54.9367 19.9133 48.01 23.66'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M19.9867 23.66C13.06 19.9167 3.84667 23.2233 0.963338 33.58C0.236671 36.19 2.43667 38.5167 5.14667 38.5167H14.1467'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M19.5434 35.9033C23.85 17.5867 44.15 17.5867 48.4567 35.9033C49.0767 38.5333 46.89 40.85 44.1867 40.85H23.8134C21.11 40.85 18.9267 38.5333 19.5434 35.9033Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                }>
                                Community
                                <br />
                                Edition
                            </PricingCard.Title>
                            <PricingCard.Body>
                                <span className='block mb-1 md:mb-2'>
                                    If you’re starting out with data streaming.
                                </span>
                                <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                                    <li>Limited features</li>
                                    <li>Basic Authentication</li>
                                    <li>2 user accounts</li>
                                    <li>Connect up to 2 Kafka clusters</li>
                                    <li>Supports all Kafka vendors</li>
                                </ul>
                            </PricingCard.Body>
                            <PricingCard.Button text='Try Now' href='/community-edition/' />
                        </PricingCard.Wrapper>

                        <PricingCard.Wrapper>
                            <PricingCard.Title
                                icon={
                                    <svg
                                        width='64'
                                        height='56'
                                        viewBox='0 0 64 56'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M7.01517 54.9494H39.0252M7.01517 54.9494V7.36832C7.01517 4.15388 9.62156 1.54749 12.836 1.54749H33.2074C36.4218 1.54749 39.0282 4.15388 39.0282 7.36832V13.1892M7.01517 54.9494H1.19434M39.0252 54.9494V13.1892H50.6668C53.8813 13.1892 56.4877 15.7955 56.4877 19.01V54.9494M39.0252 54.9494H56.4846H62.3054M27.3835 16.1011H18.6538M18.6538 27.7397H27.3835'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                }>
                                Enterprise
                                <br />
                                Edition
                            </PricingCard.Title>
                            <PricingCard.Body>
                                <span className='block mb-1 md:mb-2'>
                                    If you need self-service control for a single cluster.
                                </span>
                                <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                                    <li>Full features</li>
                                    <li>SSO Authentication</li>
                                    <li>Unlimited users</li>
                                    <li>Single Kafka visibility</li>
                                    <li>Supports all Kafka vendors</li>
                                    <li>From: $4k/year</li>
                                </ul>
                            </PricingCard.Body>
                            <PricingCard.Button text='Request a quote*' href='/request-quote/' />
                        </PricingCard.Wrapper>

                        <PricingCard.Wrapper variant='orange'>
                            <PricingCard.Title
                                variant='orange'
                                icon={
                                    <svg
                                        width='62'
                                        height='69'
                                        viewBox='0 0 62 69'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M30.7498 14.5834C34.5238 14.5834 37.5832 11.524 37.5832 7.75002C37.5832 3.97607 34.5238 0.916687 30.7498 0.916687C26.9759 0.916687 23.9165 3.97607 23.9165 7.75002C23.9165 11.524 26.9759 14.5834 30.7498 14.5834Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M30.7498 67.5834C34.5238 67.5834 37.5832 64.524 37.5832 60.75C37.5832 56.9761 34.5238 53.9167 30.7498 53.9167C26.9759 53.9167 23.9165 56.9761 23.9165 60.75C23.9165 64.524 26.9759 67.5834 30.7498 67.5834Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M30.75 14.5834V24.57'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M30.75 43.7567V53.7434'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M7.80013 27.8334C11.5741 27.8334 14.6335 24.774 14.6335 21C14.6335 17.2261 11.5741 14.1667 7.80013 14.1667C4.02618 14.1667 0.966797 17.2261 0.966797 21C0.966797 24.774 4.02618 27.8334 7.80013 27.8334Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M53.7 54.3334C57.474 54.3334 60.5334 51.274 60.5334 47.5C60.5334 43.7261 57.474 40.6667 53.7 40.6667C49.9261 40.6667 46.8667 43.7261 46.8667 47.5C46.8667 51.274 49.9261 54.3334 53.7 54.3334Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M13.7168 24.4167L22.3668 29.41'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M38.9834 39.0034L47.6334 43.9967'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M7.80013 54.3334C11.5741 54.3334 14.6335 51.274 14.6335 47.5C14.6335 43.7261 11.5741 40.6667 7.80013 40.6667C4.02618 40.6667 0.966797 43.7261 0.966797 47.5C0.966797 51.274 4.02618 54.3334 7.80013 54.3334Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M53.7 27.8334C57.474 27.8334 60.5334 24.774 60.5334 21C60.5334 17.2261 57.474 14.1667 53.7 14.1667C49.9261 14.1667 46.8667 17.2261 46.8667 21C46.8667 24.774 49.9261 27.8334 53.7 27.8334Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M13.7168 44.0834L22.3668 39.09'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M38.9834 29.4967L47.6334 24.5034'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M30.75 44.1933C36.2415 44.1933 40.6933 39.7415 40.6933 34.25C40.6933 28.7584 36.2415 24.3066 30.75 24.3066C25.2584 24.3066 20.8066 28.7584 20.8066 34.25C20.8066 39.7415 25.2584 44.1933 30.75 44.1933Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                        <path
                                            d='M30.75 44.1933C36.2415 44.1933 40.6933 39.7415 40.6933 34.25C40.6933 28.7584 36.2415 24.3066 30.75 24.3066C25.2584 24.3066 20.8066 28.7584 20.8066 34.25C20.8066 39.7415 25.2584 44.1933 30.75 44.1933Z'
                                            stroke='currentColor'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                        />
                                    </svg>
                                }>
                                Multi-Kafka
                                <br />
                                Enterprise
                            </PricingCard.Title>
                            <PricingCard.Body variant='orange'>
                                <span className='block mb-1 md:mb-2'>
                                    If you need a unified experience across multiple clusters.
                                </span>
                                <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                                    <li>Full features</li>
                                    <li>SSO Authentication</li>
                                    <li>Unlimited users</li>
                                    <li>Multi-Kafka visibility</li>
                                    <li>Supports all Kafka vendors</li>
                                </ul>
                            </PricingCard.Body>
                            <PricingCard.Button
                                text='Request a quote*'
                                href='/request-quote/'
                                variant='orange'
                            />
                        </PricingCard.Wrapper>
                    </div>
                </Wrapper>

                <HeroModule {...packageIncludesProps} />

                <div className={styles.bg_grey_20}>
                    <PreFooter
                        title={preFooterTitle}
                        reviews={ReviewsPrefooter}
                        cardsData={cardsDataPrefooter}
                    />
                </div>
            </div>
        </div>
    )
}

export default PricingPage
